import React, { Fragment, isValidElement, useRef, useEffect, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { NavContext } from '../context/NavContext'
import { BotContext } from '../context/BotContext'
import AOS from 'aos'
import TopImage from '../../images/other/top-image.jpeg'

import ParallaxContainer from "../parallax/ParallaxContainer"


const FadingTitle = ({ loadAnimation, path, title, boxText, cta, textUnderCta }) => {

    const titleRef = useRef(null)

    const { setBotShow, showOnLanding, setShowOnLanding, setActiveBot } = useContext(BotContext);
    useMemo(() => {
        if (showOnLanding && path === "?find-office") {
            setActiveBot("LandBotForAds");
            setShowOnLanding(false);
            setBotShow(true);
        }
    }, [path, showOnLanding]);

    const { animationOver, setAnimationOver } = useContext(NavContext)

    const ctaRef = useRef();
    const ctaPostition = ctaRef.current && ctaRef.current.getBoundingClientRect();

    if (!loadAnimation) {
        setAnimationOver(true)
    }

    useEffect(() => {
        AOS.refresh()
    }, [animationOver])

    return (
        <Fragment>
            <div
                className="grid grid-cols-3 xl:ml-16 relative"
                style={{
                    gridTemplateRows: 'auto',
                    marginBottom: 0,
                    opacity: animationOver ? 1 : 0
                }}
            >

                <div className="col-start-1 col-end-4 row-start-1 row-end-2 text-left mt-24 mb-8 md:-mb-8 sm:mt-4 flex items-start flex-col justify-center mx-0 md:w-10/12 sm:col-end-3 md:mt-24">
                    <div className="max-w-full mx-auto md:ml-0">
                        <h1
                            // Opacity values entered here in a style prop
                            ref={titleRef}
                            className=" transition-quick text-3xl md:text-4xl text-center sm:text-left mx-8 md:mx-0 sm:text-left"
                            style={{marginTop:"4rem", marginBottom: "6rem"}}
                        >
                            {title}
                        </h1>
                    </div>

                </div>

                <div className="hidden sm:block col-start-3 col-end-4 row-start-1 row-end-2 lg:row-end-3">
                    <div >
                        <img className="mt-64 mr-1" src={TopImage} alt="Office Image" />
                    </div>
                </div>

                <div ref={ctaRef} className="col-start-1 col-end-4 row-start-2 row-end-3  relative">
                    <ParallaxContainer
                        className="flex-col pt-0 md:pt-8 no-wrap pl-16"
                        reverse={true}
                        weighting={2}
                    >
                        <div
                            className="max-w-xs mt-3 md:max-w-xl md:min-h-min mx-auto sm:max-w-sm md:mx-0 md:ml-10 pb-64 w-full"
                            style={{ maxHeight: '160px' }}
                        >
                            <p className="h-20">{boxText}</p>
                        </div>
                    </ParallaxContainer>
                </div>
            </div>
        </Fragment>
    )
}

FadingTitle.propTypes = {
    loadAnimation: PropTypes.bool,
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
    boxText: PropTypes.string.isRequired,
    cta: PropTypes.node,
    textUnderCta: PropTypes.string.isRequired,
}

FadingTitle.defaultProps = {
    loadAnimation: false,
    path: '',
    cta: null,
}

export default FadingTitle






// Code for opacity fade on title as it travels up screen

// const activeWindow = typeof window !== "undefined" ? window : null

// State Management for the offscreen value
// const [offScreen, setOffScreen] = useState(1)

// Original Fade function
// const scrollOffPageCheck = (e) => {
//     const percent = 1 - ((activeWindow.scrollY / titleRef.current.offsetTop).toFixed(2) * 1.8)
//     if (percent < 0) return
//     if ((percent < offScreen || percent > offScreen) && percent > 0) return setOffScreen(percent < 0.1 ? 0 : percent )
//     if (percent === 1 ) return setOffScreen(percent )
// }

// Activates the fade as the component animates off screen
// useEffect(()=> {
//     activeWindow.addEventListener('scroll', scrollOffPageCheck)
//     if (offScreen !== 0) scrollOffPageCheck()
//     return () => activeWindow.removeEventListener('scroll', scrollOffPageCheck)
// })
