import React from 'react'
import PropTypes from 'prop-types'

const ThreeFeaturesAssetOwners = ({ features }) => (
    <div className="flex max-w-5xl items-start mx-auto flex-col md:flex-row">
        {features.map(({ title, subTitle }) => (
            <div key={title} className="flex-grow text-center my-4 md:mx-8 box-border lg:w-full">
                <h2 className="uppercase underline inline-block text-xl" style={{textDecorationColor: '#FECC02', textUnderlineOffset: '5px'}}>{title}</h2>
                <p className="text-left mt-6">{subTitle}</p>
            </div>
        ))}
    </div>
)

ThreeFeaturesAssetOwners.propTypes = {
    features: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            subTitle: PropTypes.string.isRequired,
        })
    ).isRequired
}

export default ThreeFeaturesAssetOwners
